import React from 'react'

import Storage from '../../lib/storage'

import styles from './component.module.scss'
import {ISubHeaderProps} from "./props";
import {GoProject, GoListOrdered, GoOrganization} from "react-icons/go"
import { Link } from "react-router-dom";

export const Layout = (props: any) => {
  const profile = Storage.getProfile();

  return (
      <div className={`header-fixed header-mobile-fixed subheader-enabled subheader-fixed aside-enabled aside-fixed aside-minimize-hoverable aside-minimize ${styles.root}`}>
        <div className={'d-flex flex-row flex-column-fluid page'}>

          <aside className={'aside aside-left aside-fixed d-flex flex-column flex-row-auto'}>
            <div className={'aside-menu-wrapper flex-column-fluid'}>

              <a href={'/'} className={styles.logo}>Metalle.Direct</a>

              <div className={'aside-menu my-4'}>
                <ul className={'menu-nav'}>
                  <li className={'menu-item menu-item-active'}>
                    <Link to="/" className={'menu-link'}>
                      <i className={'menu-icon'}>
                        <GoProject className={styles.menu}/>
                      </i>
                    </Link>
                  </li>
                  <li className={'menu-item menu-item-active'}>
                    <Link to="/employee" className={'menu-link'}>
                      <i className={'menu-icon'}>
                        <GoOrganization className={styles.menu}/>
                      </i>
                    </Link>
                  </li>
                  <li className={'menu-item menu-item-active'}>
                    <Link to='/customer' className={'menu-link'}>
                      <i className={'menu-icon'}>
                        <GoListOrdered className={styles.menu}/>
                      </i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </aside>

          <main className={'d-flex flex-column flex-row-fluid wrapper'}>
            <div className={'header header-fixed'}>
              <div className={'container-fluid d-flex align-items-stretch justify-content-between'}>
                <div className={'header-menu-wrapper header-menu-wrapper-left'} />
                <div className={'topbar'}>
                  <div className={`topbar-item ${styles.profile}`}>
                    <span className={'text-muted font-weight-bold font-size-base d-none d-md-inline mr-1'}>Hi,</span>
                      <span className={'text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3'}>
                        {profile && <>{profile.name}</>}
                        {!profile && <>PROFILNAME</>}
                      </span>
                      <span className={'symbol symbol-35 symbol-light-success'}>
                      <span className={`symbol-label font-size-h5 font-weight-bold ${styles.icon}`}>
                        {profile && <>{profile.name[0]}</>}
                        {!profile && <>?</>}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={'content d-flex flex-column flex-column-fluid'}>
              {props.children}
            </div>
          </main>

        </div>
      </div>
  );
}

export const Content = (props: any) => (
    <div className={'d-flex flex-column-fluid'}>
      <div className={styles.container}>
        {props.children}
      </div>
    </div>
)

export const SubHeader = (props: ISubHeaderProps) => (
    <div className={'subheader py-2 py-lg-4 subheader-solid'}>
      <div className={'container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap'}>
        <div className={'d-flex align-items-center flex-wrap mr-1'}>
          <div className={'d-flex align-items-baseline mr-5'}>
            {props.title &&
              <h5 className={'text-dark font-weight-bold my-2 mr-5'}>{props.title}</h5>
            }
            {props.subtitle &&
              <p className={'my-2'}>{props.subtitle}</p>
            }
          </div>
        </div>

        {(props as any).children}
      </div>
    </div>
);
