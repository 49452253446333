import React from "react";
import FunctionsClient from "../../lib/functions-client";
import { Layout, Content, SubHeader } from "../../layouts/default";
import styles from "./component.module.scss";
import { IState } from "./state";
const moment = require("moment");

class Employee extends React.Component<any, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      loaded: false,
      data: [],
      value: "Gestern",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    FunctionsClient.timeCompareDay().then((list) => {
      this.setState({
        data: list,
        loaded: true,
      });
    });
  }

  handleChange(event: any) {
    this.setState({ loaded: false });
    if (event.target.value === "Gestern") {
      FunctionsClient.timeCompareDay().then((list) => {
        this.setState({
          data: list,
          loaded: true,
          value: "Gestern",
        });
      });
    } else if (event.target.value === "Letzte 7 Tage") {
      FunctionsClient.timeCompareWeek().then((list) => {
        this.setState({
          data: list,
          loaded: true,
          value: "Letzte 7 Tage",
        });
      });
    } else {
      FunctionsClient.timeCompareMonth().then((list) => {
        this.setState({
          data: list,
          loaded: true,
          value: "Letzte 30 Tage",
        });
      });
    }
  }

  render() {
    return (
      <Layout>
        <SubHeader title={"Übersicht Arbeitszeiten"} />
        <Content>
          <div className={styles.selectContainer}>
            <div className={styles.selectBox}>
              <div className={styles.header}>Zeitraum</div>
              <select className={styles.select} onChange={this.handleChange}>
                <option>Gestern</option>
                <option>Letzte 7 Tage</option>
                <option>Letzte 30 Tage</option>
              </select>
            </div>
          </div>
          {this.state.loaded && (
            <div className={styles.tableContainer}>
              <div className={styles.head}>
                <div className={styles.tableHead}></div>
                <div className={styles.tableHead}>
                  Soll
                  {this.state.value === "Gestern" && (
                    <>
                      {" "}
                      (
                      {moment(new Date())
                        .subtract(1, "days")
                        .format("DD.MM.YYYY")}
                      )
                    </>
                  )}
                  {this.state.value === "Letzte 7 Tage" && (
                    <>
                      {" "}
                      (
                      {moment(new Date())
                        .subtract(7, "days")
                        .format("DD.MM.YYYY")}{" "}
                      -{" "}
                      {moment(new Date())
                        .subtract(1, "days")
                        .format("DD.MM.YYYY")}
                      )
                    </>
                  )}
                  {this.state.value === "Letzte 30 Tage" && (
                    <>
                      {" "}
                      (
                      {moment(new Date())
                        .subtract(30, "days")
                        .format("DD.MM.YYYY")}{" "}
                      -{" "}
                      {moment(new Date())
                        .subtract(1, "days")
                        .format("DD.MM.YYYY")}
                      )
                    </>
                  )}
                </div>
                <div className={styles.tableHead}>
                  Zeit in Ticketsystemen gebucht
                </div>
                <div className={styles.tableHead}>Zeit in Absence gebucht</div>
                <div className={styles.tableHead}>Fehlzeit Ticketsystem</div>
                <div className={styles.tableHead}>Fehlzeit Absence</div>
              </div>
              {this.state.data.map((employee: any, index: number) => {
                return (
                  <div className={styles.tableContent} key={index}>
                    <div className={styles.table}>
                      <img src={employee.employee.img} className={styles.img} alt={employee.employee.name} />
                      {employee.employee.forename} {employee.employee.name}
                    </div>
                    <div className={styles.table}>{employee.workingTime} h</div>
                    <div className={styles.table}>
                      {employee.ticketsystem} h
                    </div>
                    <div className={styles.table}>{employee.absence} h</div>
                    <div className={styles.table}>
                      {employee.differences.ticketsystem > 0 && (
                        <div className={styles.box}>
                          {employee.differences.ticketsystem} h
                        </div>
                      )}
                    </div>
                    {employee.differences.absence > 0 && (
                      <div className={styles.table}>
                        <div className={styles.box}>
                          {employee.differences.absence} h
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </Content>
      </Layout>
    );
  }
}

export default Employee;
