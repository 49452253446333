import React, {FormEvent} from 'react'

import FunctionsClient from '../../lib/functions-client'

import Layout from '../../layouts/empty'

import styles from './component.module.scss'

import logo from '../../assets/media/logo.svg'

class LoginPage extends React.Component<any, any> {

  usernameRef: React.RefObject<any>;
  passwordRef: React.RefObject<any>;

  constructor(props: any) {
    super(props);

    this.usernameRef = React.createRef();
    this.passwordRef = React.createRef();

    this.state = {
      isSending: false,
      hasError: false
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (this.state.isSending) {
      return;
    }

    this.setState({
      isSending: true,
      hasError: false,
    });

    FunctionsClient.login(
        this.usernameRef.current.value,
        this.passwordRef.current.value
    ).then(result => {
      this.setState({
        hasError: !result,
        isSending: false,
      });

      if (result && this.props.onLoginSuccess) {
        this.props.onLoginSuccess();
      }
    }).catch(e => {
      this.setState({
        isSending: false,
      });

      alert('Fehler: ' + e);
    });
  }

  render() {
    return (
      <Layout>
        <>
          <a href={'/'} className={`text-center pt-10 pb-10 mb-20 ${styles.logo}`}>
            <img src={logo} alt={'Digitalwert Logo'} className={'max-h-75px'} />
          </a>

          <form onSubmit={this.onSubmit} className={`${styles.form} ${this.state.hasError ? styles.failed : ''}`}>
            <div className="form-group">
              <label className={'font-size-h6 font-weight-bolder text-dark'} htmlFor="email">E-Mail</label>
              <input autoComplete={'username'} ref={this.usernameRef} type="email" className="form-control form-control-solid h-auto py-7 px-6 rounded-lg" id="login-email" />
            </div>

            <div className="form-group">
              <label className={'font-size-h6 font-weight-bolder text-dark'} htmlFor="password">Password</label>
              <input autoComplete={'current-password'} ref={this.passwordRef} type="password" className="form-control form-control-solid h-auto py-7 px-6 rounded-lg" id="login-password" />
            </div>

            <div className={'pb-lg-0 pb-5 text-center'}>
              <button type="submit" className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3">Anmelden</button>
            </div>
          </form>
        </>
      </Layout>
    );
  }
}

export default LoginPage
