import React from 'react';
import {Editor, EditorState, convertToRaw, convertFromRaw} from 'draft-js';
import 'draft-js/dist/Draft.css';
import { IState } from "./state";
import styles from "./component.module.scss";
import FunctionsClient from "../../../lib/functions-client";

class MyEditor extends React.Component<any, IState> {
    private myRef: any;
    constructor(props: any) {
      super(props);
      this.myRef = React.createRef();
      this.focus = this.focus.bind(this);
      this.state = {
        editorState: EditorState.createEmpty(), 
        loaded: false,
        messages: []
    };
      this.onChange = editorState => this.setState({editorState});
    }

    componentDidMount() {
        FunctionsClient.getNotes().then((list: any) => {
          this.setState({
            loaded: true,
            messages: list.notes
          });
          console.log(list.notes);
        });
    }
    
    onChange(editorState: any) {
        this.setState({editorState});
    }

    submit() {
        let contentState = this.state.editorState.getCurrentContent();
        let note = {content: convertToRaw(contentState)};
        let noteToSubmit = JSON.stringify(note.content);
        const payload = {note: noteToSubmit, date: new Date()};
        const oldMessages = this.state.messages;
        oldMessages.push(payload);
        this.setState({messages: oldMessages});
        FunctionsClient.postNotes(payload);
        this.setState({editorState: EditorState.createEmpty()});
    }
  
    focus() {
        this.myRef.current.focus();
    }

    render() {
      return (
          <div className={styles.container}>
              <div className={styles.header}>
                  Notizen
              </div>
              <div className={styles.scroll}>
                  {this.state.loaded && (
                      <>
                      {
                          this.state.messages.map((message: any, index: number) => {
                              const m = convertFromRaw(JSON.parse(message.note));
                              const editorState = EditorState.createWithContent(m)
                            return (
                                <div key={index} className={styles.note}>
                                    <Editor onChange={this.onChange} editorState={editorState} readOnly={true} />
                                    <div className={styles.date}>
                                        {new Date(message.date).toDateString()}
                                    </div>
                                </div>
                            )        
                          })
                      }
                      </>
                  )}
              </div>
              <div className={styles.editor} onClick={this.focus}>
                <Editor editorState={this.state.editorState} onChange={this.onChange} ref={this.myRef}/>
              </div>
              <div className={styles.button}>
                  <div className={styles.submit} onClick={() => {this.submit()}}>
                      Notiz Speichern
                  </div>
              </div>
          </div>

      );
    }
}

export default MyEditor;