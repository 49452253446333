import React, { useEffect, useState } from "react";

import FunctionsClient from "../../../lib/functions-client";
import styles from "./component.module.scss";
import img from "../img-person-2.png";

const moment = require("moment");

const Project = (props: any) => {
  const [message, setMessage] = useState("");
  const [projectId, setProjectId] = useState(props.project.projectId);
  const [project, setProject] = useState(props.project);
  const [messages, setMessages] = useState([{}])
  const [loaded, setLoaded] = useState(false);
  const [mLoaded, setMLoaded] = useState(false);

  useEffect(() => {
    let count = 0;
    if (!loaded && count <= 1) {
      count++;
      FunctionsClient.getProjectForID({id: projectId}).then(res => {
        if (!res) { return }
        setProject(res.projectManager);
        setLoaded(true);
      })
    }
    if (!mLoaded) {
      FunctionsClient.getMessages({ id: projectId }).then((res) => {
        if (!res) { return }
        setMessages(res);
        setMLoaded(true);
      });
    }
  });

  function handleChange(event: any) {
    setMessage(event.target.value);
  }
  function unsubscribeProject(project: any) {
    if (
      window.confirm(
        "Möchtest du die Controlling Mails für dieses Projekt wirklich abschalten?"
      )
    ) {
      FunctionsClient.unsubscribeProject({ id: project.projectId });
      props.handleUnsubscribe(projectId);
    }
  }

  function handleSubmit(event: any) {
    let update = messages;
    if (message.length !== 0) {
      if (messages && messages.length !== 0) {
        update.push({ note: message, date: new Date(), id: 0 });
      } else {
        update = [{ note: message, date: new Date(), id: 0 }];
      }
      FunctionsClient.messages({
        id: project.projectId,
        message: message,
      });
      setMessage("");
      setMessages(update)
    }
    event.preventDefault();
  }

  return (
    <div style={{ width: "100%" }} className={styles.layout}>
      {(loaded) && (
        <div className={styles.layout} style={{ position: "fixed", right: 0 }}>
          <div className={styles.header}>
            <div className={styles.name}>
              <div>{project.projectName}</div>
              <div className={styles.company}>{project.company}</div>
              <div className={styles.date}>
                {new Date(project.lastUpdated).toDateString()}
              </div>
            </div>
            {project.projectType === "agil" && (
              <>
                <div
                  className={styles.box}
                  style={{
                    backgroundColor: project.monthly.color,
                  }}
                >
                  Monatliches Kontingent
                  <div className={styles.number}>{Number(project.monthly.quota).toFixed(2)} h</div>
                </div>
                <div
                  className={styles.box}
                  style={{
                    backgroundColor: project.monthly.color,
                  }}
                >
                  Gebucht diesen Monat
                  <div className={styles.number}>
                    {Number(project.monthly.booked).toFixed(2)} h
                  </div>
                </div>
              </>
            )}
            {project.projectType === "classic" && (
              <>
                <div
                  className={styles.box}
                  style={{
                    backgroundColor: project.monthly.color,
                  }}
                >
                  Stundensatz
                  <div className={styles.number}>
                    {Number(project.monthly.hourlyRate).toFixed(2)} €
                  </div>
                </div>
                <div
                  className={styles.box}
                  style={{
                    backgroundColor: project.monthly.color,
                  }}
                >
                  Endstundensatz
                  <div className={styles.number}>
                    {Number(project.monthly.calculatedRate).toFixed(2)} €
                  </div>
                </div>
              </>
            )}
            {project.projectType === "service" && (
              <>
                <div
                  className={styles.box}
                  style={{
                    backgroundColor: project.monthly.color,
                  }}
                >
                  Monatliches Kontingent
                  <div className={styles.number}>{Number(project.monthly.quota).toFixed(2)} h</div>
                </div>
                <div
                  className={styles.box}
                  style={{
                    backgroundColor: project.monthly.color,
                  }}
                >
                  Geleistet
                  <div className={styles.number}>
                    {Number(project.monthly.booked).toFixed(2)} h
                  </div>
                </div>
              </>
            )}
          </div>
          <div className={styles.people}>
            <div className={styles.manager}>
              <div>Projektleiter</div>
              <div className={styles.image}>
                <img
                  src={project.managerImage}
                  className={styles.image}
                  style={{ margin: "unset" }}
                  alt={project.managerImage}
                />
              </div>
            </div>
            {project.team && project.team.length !== 0 && (
              <div className={styles.team}>
                <div>Team</div>
                <div className={styles.container}>
                  {project.team.map((m: any, index: number) => {
                    return (
                      <div className={styles.image} key={index}>
                        <img
                          src={m.img !== "" ? m.img : img}
                          className={styles.image}
                          style={{ margin: "unset" }}
                          alt={""}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          <div className={styles.progress}>
            <div
              style={{
                width: project.progress + "%",
                backgroundColor: "rgb(152 10 136)",
              }}
              className={styles.progressbar}
            ></div>
          </div>
          <div className={styles.budget}>
            {project.projectType === "agil" && (
              <>
                <div className={styles.container}>
                  <div className={styles.box}>
                    <div>Budget:</div>
                    <div className={styles.number}>
                      {project.budget.money.full} €
                    </div>
                  </div>
                  <div className={styles.box}>
                    <div>Verbraucht:</div>
                    <div className={styles.number}>
                      {project.budget.money.used} €
                    </div>
                  </div>
                  <div className={styles.box}>
                    <div>Rest:</div>
                    <div className={styles.number}>
                      {project.budget.money.remaining} €
                    </div>
                  </div>
                </div>
                <div className={styles.container}>
                  <div className={styles.box}>
                    <div>Budget:</div>
                    <div className={styles.number}>
                      {project.budget.time.full} h
                    </div>
                  </div>
                  <div className={styles.box}>
                    <div>Verbraucht:</div>
                    <div className={styles.number}>
                      {project.budget.time.used} h
                    </div>
                  </div>
                  <div className={styles.box}>
                    <div>Rest:</div>
                    <div className={styles.number}>
                      {project.budget.time.remaining} h
                    </div>
                  </div>
                </div>
              </>
            )}
            {project.projectType === "classic" && (
              <>
                <div className={styles.container}>
                  <div className={styles.box}>
                    <div>Verkauft:</div>
                    <div className={styles.number}>
                      {project.budget.timeSelled} h
                    </div>
                  </div>
                  <div className={styles.box}>
                    <div>Geleistet:</div>
                    <div className={styles.number}>
                      {project.budget.timeDone} h
                    </div>
                  </div>
                  <div className={styles.box}>
                    <div>Wahrscheinlich Benötigt:</div>
                    <div className={styles.number}>
                      {project.budget.timeNeeded} h
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className={styles.messages}>
            {messages && messages.length !== 0 && (
              <div className={styles.scroll}>
                {messages.sort((a: any, b: any) => moment(new Date(b.date)) - moment(new Date(a.date)) ).map((m: any, index: number) => {
                  return (
                    <div key={index} className={styles.message}>
                      <div>{m.note}</div>
                      <div className={styles.date}>
                        {new Date(m.date).toDateString()}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <form onSubmit={handleSubmit} className={styles.form}>
              <input
                type="text"
                value={message}
                onChange={handleChange}
                className={styles.input}
              />
              <input
                type="submit"
                value="Nachricht absenden"
                className={styles.submit}
              />
            </form>
          </div>
          <div className={styles.sprint}>
            {project.currentSprint && project.currentSprint.length !== 0 && (
              <div className={styles.scroll}>
                <div
                  className={styles.issue}
                  style={{ backgroundColor: "unset" }}
                >
                  <div className={styles.title}>Ticket</div>
                  <div className={styles.selled}>Verkauft</div>
                  <div className={styles.done}>Geleistet</div>
                  <div className={styles.issueProgress}>Fortschritt</div>
                </div>
                {project.currentSprint
                  .sort((a: any, b: any) => {
                    return (
                      (b.voraussichtlicherAufwand
                        ? Number(b.voraussichtlicherAufwand)
                        : 0) -
                      (b.geschatzt ? Number(b.geschatzt) : 0) -
                      ((a.voraussichtlicherAufwand
                        ? Number(a.voraussichtlicherAufwand)
                        : 0) -
                        (a.geschatzt ? Number(a.geschatzt) : 0))
                    );
                  })
                  .map((issue: any, index: number) => {
                    return (
                      <div
                        key={index}
                        className={styles.issue}
                        onClick={() =>
                          window.open(
                            "https://www.tickets.digitalwert.net/issues/" +
                              issue.nummer,
                            "_blank"
                          )
                        }
                      >
                        <div className={styles.title}>{issue.titel}</div>
                        <div className={styles.selled}>
                          {issue.geschatzt
                            ? Number(issue.geschatzt).toFixed(2)
                            : 0}{" "}
                          h
                        </div>
                        <div className={styles.done}>
                          {issue.aufgewand
                            ? Number(issue.aufgewand).toFixed(1) + " h"
                            : 0 + " h"}{" "}
                          (
                          {issue.voraussichtlicherAufwand
                            ? Number(issue.voraussichtlicherAufwand).toFixed(2)
                            : 0}{" "}
                          h)
                        </div>
                        <div className={styles.issueProgress}>
                          {issue.erledigt} %
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
          <div className={styles.buttons}>
            {project.link.new.length !== 0 && (
              <div
                className={styles.button}
                onClick={() => {
                  window.open(project.link.new);
                }}
              >
                <div>
                  Zeiterfassung Bericht {moment(new Date()).format("YY-MM")}
                </div>
              </div>
            )}
            {project.link.new.length !== 0 && (
              <div
                className={styles.button}
                onClick={() => {
                  window.open(project.link.old);
                }}
              >
                <div>
                  Zeiterfassung bericht{" "}
                  {moment(new Date()).subtract(1, "months").format("YY-MM")}
                </div>
              </div>
            )}
            <div
              className={styles.button}
              onClick={() => {
                unsubscribeProject(project);
              }}
            >
              <div>Projekt entfernen</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Project;