import React from 'react'

import styles from './component.module.scss'

export default (props: any) => (
  <div className={`header-fixed header-mobile-fixed subheader-enabled subheader-fixed aside-enabled aside-fixed aside-minimize-hoverable ${styles.root}`}>
    <div className={'d-flex flex-column flex-root'}>
      <div className={'d-flex flex-column flex-lg-row flex-column-fluid bg-white'}>
          <div className={'flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto'}>
              <div className={'d-flex flex-column flex-column-fluid flex-center'}>
                  {props.children}
              </div>
          </div>
      </div>
    </div>
  </div>
)
