import React from 'react'
import Skeleton from 'react-loading-skeleton'

import FunctionsClient from '../../lib/functions-client'

import {IState} from "./state";

import {Layout, Content, SubHeader} from "../../layouts/default";
import Card from "../../components/card";

import styles from './component.module.scss'

class CustomerScoringPage extends React.Component<any, IState> {

  constructor(props: any) {
    super(props);

    this.state = {
      loaded: false,
      scores: [],
    };
  }

  componentDidMount() {
    FunctionsClient.customersScoring()
        .then(list => {
          this.setState({
            loaded: true,
            scores: list,
          });
        });
  }

  render() {
    return (
      <Layout>
        <SubHeader title={'Kunden-Scoring'} />
          <Content>
            <Card bodyClassName={styles.list} title={'Score absteigend: 1.0 - 0.0'} collapsible={false}>
              {!this.state.loaded &&
                <Skeleton count={10} height={24} />
              }
              {this.state.loaded &&
                <table className={'table'}>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.scores.map((item, i) => (
                      <tr key={i} className={`${(i + 1) % 2 === 0 ? styles.odd : ''}`}>
                        <td>{i + 1}</td>
                        <td>{item.name}</td>
                        <td className={!item.complete ? styles.incomplete : ''} title={!item.complete ? 'noch nicht komplett bewertet' : undefined}>
                          {item.score}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              }
            </Card>
          </Content>
        </Layout>
    );
  }
}

export default CustomerScoringPage
