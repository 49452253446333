import Storage from '../storage'
import FunctionsClientUnauthorizedError from "./error/unauthorized";

interface IResponseData {
  status: boolean
  data: any
}

class FunctionsClient {

  _request(
      resource: string,
      method = 'get',
      payload: any = null,
      auth = true,
  ): Promise<any | null> {
    const headers = new Headers({
      'Content-Type': 'application/json',
    });

    if (auth) {
      const token = Storage.getToken();
      if (token !== null) {
        headers.append('Authorization', 'Bearer ' + token);
      }
    }

    return fetch(process.env.REACT_APP_FUNCTIONS_BASEURL + resource, {
      method,
      headers,
      body: payload === null ? null : JSON.stringify(payload),
    })
        .then(response => {
          if (response.status === 401 && auth) {
            throw new FunctionsClientUnauthorizedError();
          }
          return response;
        })
        .then(response => {
          if (response.status === 201) {
            return {
              status: true,
              data: null,
            };
          }

          if (response.status >= 400) {
            throw new Error('request failed');
          }

          return response.json();
        })
        .then((response: IResponseData) => {
          if (!response.status) {
            return null;
          }

          return response.data;
        })
        .catch(e => {
          if (e instanceof FunctionsClientUnauthorizedError) {
            Storage.clear();
            window.location.reload();
            return;
          }

          throw e;
        });
  }

  login(username: string, password: string): Promise<boolean> {
    return this._request(
        'backendLogin',
        'post',
        {
          username,
          password,
        },
        false
    ).then(data => {
      if (data === null) {
        return false;
      }

      if (!data.token || !data.profile) {
        throw new Error('invalid response');
      }

      Storage.setToken(data.token);
      Storage.setProfile(data.profile);

      return true;
    });
  }

  customersScoring(): Promise<Array<any>> {
    return this._request('backendCustomerScoring');
  }

  projectsOld(): Promise<Array<any>> {
    return this._request('backendProjectsOld');
  }

  projects(): Promise<Array<any>> {
    return this._request('backendControllingProjects');
  }

  messages(payload: {id: number, message: string}): Promise<void> {
    return this._request('backendProjectComment', 'POST', payload);
  }

  getMessages(payload: {id: number}): Promise<any> {
    return this._request('backendGetProjectComments', 'POST', payload);
  }

  unsubscribeProject(payload: {id: number}): Promise<void> {
    return this._request('backendUnsubscribe', 'POST', payload);
  }

  getNotes(): Promise<Array<any>> {
    return this._request('backendNotes');
  }

  postNotes(payload: {note: string, date: Date}): Promise<void> {
    return this._request("backendPostNotes", "POST", payload);
  }

  timeCompareDay(): Promise<Array<any>> {
    return this._request('backendEmployeeDay');
  }

  timeCompareWeek(): Promise<Array<any>> {
    return this._request('backendEmployeeWeek');
  }

  timeCompareMonth(): Promise<Array<any>> {
    return this._request('backendEmployeeMonth');
  }

  getProjectForID(payload: {id: number}): Promise<any> {
    return this._request('backendProjectForID', 'POST', payload);
  }
}

export default new FunctionsClient()
