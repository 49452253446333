import React from 'react';

import Storage from './lib/storage'

import LoginPage from './pages/login/login'
import CustomerScoringPage from './pages/customer-scoring/customer-scoring'

import './styles/theme.scss'
import Projects from './pages/projects/projects';
import Employee from './pages/employee/employee';
import { BrowserRouter as Router, Route } from 'react-router-dom';

class App extends React.Component<any, any> {

  constructor(props: any) {
    super(props);

    const authToken = Storage.getToken();

    this.state = {
      isAuthenticated: authToken != null,
    };

    this.onLoginSuccess = this.onLoginSuccess.bind(this);
  }

  onLoginSuccess() {
    this.setState({
      isAuthenticated: true,
    });
  }

  render() {
    return (
        <>
          {!this.state.isAuthenticated &&
            <LoginPage onLoginSuccess={this.onLoginSuccess} />
          }

          {this.state.isAuthenticated &&
            <Router>
              <Route exact path="/" component={Projects}/>
              <Route exact path="/employee" component={Employee}/>
              <Route exact path="/customer" component={CustomerScoringPage}/>
            </Router>
          }
        </>
    );
  }
}

export default App;
