import React from "react";

import FunctionsClient from "../../lib/functions-client";
import { Layout, Content, SubHeader } from "../../layouts/default";
import styles from "./component.module.scss";
import { IState } from "./state";
import img from "./img-person-2.png";
import { GrNotes } from 'react-icons/gr';
import MyEditor from './notes/notes';
import Project from "./project/project";

class Projects extends React.Component<any, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      loaded: false,
      data: [],
      clickedProject: null,
      message: "",
      notes: false,
      textNote: "",
      key: -1,
    };
    this.handleUnsubscribe = this.handleUnsubscribe.bind(this)
  }

  componentDidMount() {
    FunctionsClient.projects().then((list) => {
      this.setState({
        loaded: true,
        data: list,
      });
      console.log(list);
    });
    FunctionsClient.timeCompareDay().then((list) => {});
  }

  triggerOldCloudFunction() {
    this.setState({loaded: false, data: []});
    FunctionsClient.projectsOld().then((list) => {
      this.setState({
        loaded: true,
        data: list,
      });
    });
  }

  handleUnsubscribe(projectId: string) {
    let updated = this.state.data.projectManager.map((manager: any) => {
      let temp = manager;
      temp.projects = manager.projects.filter((f: any) => f.projectId !== projectId);
      return temp;
    })
    console.log(updated);
    this.setState({clickedProject: null, data: { projectManager: updated }});
  }

  render() {
    return (
      <Layout>
        <SubHeader title={"Aktuelle Projekte"} />
        <Content>
          <div className={styles.container}>
            {/* List */}
            <div className={styles.layout}>
              {this.state.loaded && (
                <>
                  <div>
                    {this.state.data.projectManager.map((m: any, index: number) => {
                      return (
                        <div className={styles.list} key={index}>
                        <div className={styles.manager}>
                          <div className={styles.image}>
                            <img
                              src={m.image !== "" ? m.image : img}
                              className={styles.image}
                              alt=""
                            />
                          </div>
                          <div className={styles.name}>
                            {m.forename} {m.name}
                          </div>
                        </div>
                        {m.projects.map((pro: any, index: number) => {
                          return (
                            <div
                              className={styles.projects}
                              onClick={() => {
                                this.setState({ clickedProject: pro, notes: false, key: index });
                              }}
                              style={{
                                backgroundColor:
                                  this.state.clickedProject &&
                                  this.state.clickedProject.projectId ===
                                    pro.projectId
                                    ? "black"
                                    : "",
                              }}
                              key={index}
                            >
                              <div
                                className={styles.color}
                                style={{
                                  backgroundColor: pro.projectType === "agil" ? pro.color : (pro.monthly
                                    ? pro.monthly.color
                                    : "#111111"),
                                }}
                              >
                                <span className={styles.letter}>{pro.projectType === "agil" ? "A" : (pro.projectType === "service" ? "S" : "K")}</span>
                              </div>
                              <div className={styles.name}>
                                <div>{pro.projectName}</div>
                                <div className={styles.company}>
                                  {pro.company}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      );
                    })}
                  </div>
                  <div className={styles.list} style={{cursor: "pointer"}} onClick={() => {this.setState({notes: true, clickedProject: null})}}>
                    <div className={styles.manager}>
                      <div className={styles.image}>
                        <GrNotes className={styles.image} />
                      </div>
                      <div className={styles.name}>
                        Notizen
                      </div>
                    </div>
                  </div>
                  <div className={styles.list} style={{cursor: "pointer"}} onClick={() => {this.triggerOldCloudFunction()}}>
                    <div className={styles.manager}>
                      <div className={styles.image}></div> 
                      <div className={styles.name}>
                        Cloudfunction
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            {this.state.loaded && this.state.clickedProject !== null && (
              <div style={{position: "fixed", right: 0}}>
                <Project key={this.state.key} project={this.state.clickedProject} handleUnsubscribe={this.handleUnsubscribe}/>
              </div>
            )}
            {this.state.notes === true && (
              <div className={styles.layout} style={{position: "fixed", right: 0}}>
                <MyEditor />
              </div>
            )}
          </div>
        </Content>
      </Layout>
    );
  }
}

export default Projects;
